import { css } from '@emotion/react'
import CTAImg from "@/images/img/cta.png"

export const CTASection = css`
  background: #776AAE;
  padding: 160px 0px;
  font-family:"owners-narrow";
  font-variation-settings: "wght" 700;
  scroll-margin-top: 117px;
  overflow: hidden;
  position: relative;
  h3 {
    color: #CFC5E5;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 48px */
    text-transform: uppercase;
    height: 100%;
    margin-bottom: 0px;
    span {
      color: #EAECED;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding-top: 0px;
    padding-bottom: 80px;
    h3 {
      margin-top: 56px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding-top: 0px;
    padding-bottom: 80px;
    h3 {
      margin-top: 56px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding-top: 0px;
    padding-bottom: 80px;
    h3 {
      margin-top: 56px;
    }
  }
`
export const ImgPosition = css`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  z-index: 10;
  background-image: url(${CTAImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  img {
    width: 100%;
    height: 100%;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    position: initial;
    width: 100%;
    height: 314px;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    position: initial;
    width: 100%;
    height: 414px;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    background-position: right;
  }
`



export const DDesktop = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: flex;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: flex;
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`