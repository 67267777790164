/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"

import { 
  contatosEVisitas,
  btnEnviar
}  from '../assets/styles/Contato.styles'

// const handlePhone = (event) => {
//   let input = event.target
//   input.value = phoneMask(input.value)
// }

// const phoneMask = (value) => {
//   if (!value) return ""
//   value = value.replace(/\D/g,'')
//   value = value.replace(/(\d{2})(\d)/,"($1) $2")
//   value = value.replace(/(\d)(\d{4})$/,"$1-$2")
//   return value
// }

const Contatos = () => {
  return (
    <Fragment>
      <section css={contatosEVisitas} id="contatos">
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div className="content-section">
                <h3>Seu negócio está crescendo na velocidade que precisa?</h3>
                <p style={{ marginBottom: 32 }}>Tenha a Fractal como parceira da sua escola. Faça seu cadastro e entraremos em contato.</p>
              </div>
            </Col>
            <Col lg={1} md={1} sm={12} />
            <Col lg={5} md={5} sm={12}>
              <div>
                <form
                  name='contato'
                  method='POST'
                  action='/sucesso'
                  data-netlify='true'
                >
                  <input
                    type='hidden'
                    name='form-name'
                    value='contato'
                  />
                  <p>
                    <input type="text" name="name" placeholder="NOME" required/>
                  </p>
                  <p>
                    <input type="text" name="email" placeholder="E-MAIL" required/>
                  </p>
                  <p>
                    <input type="tel" name="celular" placeholder="CELULAR" required />
                  </p>
                  <select name="cargo" id="cargo">
                    <option value="">CARGO</option>
                    <option value="mantenedor">MANTENEDOR</option>
                    <option value="gestor">GESTOR</option>
                    <option value="diretor">DIRETOR</option>
                    <option value="investidor">INVESTIDOR</option>
                  </select>
                  <p>
                    <input type="text" name="escola" placeholder="ESCOLA" required/>
                  </p>
                  <select name="segmento" id="segmento">
                    <option value="">SEGMENTO</option>
                    <option value="ed. infantil">ED. INFANTIL</option>
                    <option value="ens. fundamental">ENS. FUNDAMENTAL</option>
                    <option value="ens. medio">ENS. MÉDIO</option>
                    <option value="pre vestibular">PRÉ VESTIBULAR</option>
                    <option value="todos">TODOS</option>
                  </select>
                  <button css={btnEnviar} type="submit">VAMOS CONVERSAR!</button>
                </form>
              </div>
            </Col>
            <Col lg={1} md={1} sm={12} />
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default Contatos;
