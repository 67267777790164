/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import { Fragment } from "react";
import Video from './video'
import { useEffect, useState } from 'react'

import { 
  headerSection,
  videoSection,
}  from '../assets/styles/HeaderContent.styles'

const HeaderContent = () => {
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500)
    }

    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth <= 500)
      window.addEventListener('resize', handleResize)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])
  return (
    <Fragment>
      <section css={headerSection}>
        <Container>
          <div style={{ left: 0, minHeight: '100%', minWidth: '100%' }}>
            {isMobile ? (
              <Video
                videoSrcURL='https://player.vimeo.com/video/892679831?h=e42f98371f&api=1&background=1&autoplay=1&loop=1&autopause=0'
              />
            ) : (
              <Video
                videoSrcURL='https://player.vimeo.com/video/892628734?h=e42f98371f&api=1&background=1&autoplay=1&loop=1&autopause=0'
              />
            )}
          </div>
          <div css={videoSection}>
            <Row>
              <Col lg={9} md={9} sm={12}>
                <h1>Toda <span>[re]</span>invenção tem um começo</h1>
              </Col>
            </Row>
            <Row>
              <Col lg={7} md={7} sm={12} />
              <Col lg={5} md={5} sm={12}>
                <p style={{ paddingBottom: 40 }}>Somos o ponto de partida para reinventar a relação entre pessoas, processos e plataformas no ambiente escolar. Um movimento para aumentar substancialmente os resultados operacionais das escolas, com maior eficiência de processos e liquidez financeira, promovendo um crescimento sustentável.</p>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default HeaderContent;
