/** @jsx jsx */
import { Container, Row, Col } from "react-bootstrap";
// import Paginations from "@/components/paginations";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import BlogCard from "@/components/blog-card";

import { jsx } from "@emotion/react";
import { commonSection } from "../assets/styles/layout.styles";

SwiperCore.use([Pagination,Navigation]);

const BlogOne = ({ posts, url }) => {
  const carouselOptions = {
    spaceBetween: 16,
    loop: false,
    // slidesPerView: 'auto',
    // pagination: {
    //   el: "#client-carousel-pagination",
    //   type: "bullets",
    //   clickable: true,
    // },
    navigation: {
      nextEl: "#main-slider-next",
      prevEl: "#main-slider-prev",
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
          pagination: {
          el: "#client-carousel-pagination",
          type: "bullets",
          clickable: true,
        },
      },
      576: {
        slidesPerView: 1,
      },
      992: {
        slidesPerView: 'auto',
      },
      1200: {
        slidesPerView: 'auto',
      },
    },
  };

  return (
    <section css={commonSection} className="blogPage">
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Swiper {...carouselOptions}>
              {posts.map((post, index) => (
                <SwiperSlide key={index}>
                  <BlogCard url={url} data={post} />
                </SwiperSlide>
              ))}
              <div
                className="swiper-pagination"
                id="client-carousel-pagination"
              ></div>
              <div className="swiper-button-prev" id="main-slider-prev">
                
              </div>
              <div className="swiper-button-next" id="main-slider-next">
               
              </div>
            </Swiper>
          </Col>
        </Row>
        {/* <Paginations /> */}
      </Container>
    </section>
  );
};

export default BlogOne;