import { css } from '@emotion/react'

export const SolucoesSectio = css`

  background: #EAECED;
  /* scroll-margin-top: 117px; */
  font-family:"owners-narrow";
  font-variation-settings: "wght" 700;
  padding: 80px 0;
  .container {
    width: 90%;
    margin: 0 auto;
  }

  #cards {
    --cards: 5;
    --cardMargin: 0;
    --cardHeight: 79vh;
    --cardTopPadding: 1.5em;
    list-style: none;
    padding-left: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(var(--cards), var(--cardHeight));
    gap: var(--cardMargin);
    padding-bottom: calc(var(--cards) * var(--cardTopPadding));
    margin-bottom: var(--cardMargin);
    /* background: transparent; */
    @media (min-width: 320px) and (max-width: 767px) {
      --cardHeight: auto;
    }

    @media (min-width: 481px) and (max-width: 767px) {
      --cardHeight: auto;
    }

    @media (min-width: 768px) and (max-width: 990px) {
      --cardHeight: auto;
    }
  }

  #card1 {
    --index: 1;
    background: transparent !important;
    border: none;
  }
  #card2 {
    --index: 2;
    background: transparent !important;
    border: none;
  }
  #card3 {
    --index: 3;
    background: transparent !important;
    border: none;
  }
  /* #card4 {
    --index: 4;
  } */

  .card {
    position: sticky;
    top: 58px;
    /* padding-top: calc(4) * 1.5em; */
    padding-top: calc(var(--index) * 4em);
    margin-left: calc(var(--index) * 2em);
    height: fit-content;
  }

  #card1 .card-body {
    background-color: #EAECED;
  }
  #card2 .card-body {
    /* background-color: #7EC4CF; */
    background-color: #EAECED;
  }
  #card3 .card-body {
    background-color: #EAECED;
  }
  #card4 .card-body {
    background-color: #EAECED;
  }

  .card-body {
	box-sizing: border-box;
	padding: 16px 30px 30px;
	border-radius: 16px;
  border: 2px solid #484848;
	/* height: var(--cardHeight); */
	display: flex;
  flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	transition: all 0.5s;
  max-width: 460px;
}

  h3 {
    color: #484848;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 48px */
    text-transform: uppercase;
    width: 100%;
    text-align: left;
    margin-bottom: 32px;
  }

  p {
    color: #484848;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
  }
  @media (min-width: 320px) and (max-width: 767px) {
    .card {
      margin-left: 0px;
    }
    h3 {
      font-size: 40px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    .card {
      margin-left: 0px;
    }
    h3 {
      font-size: 40px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    .card {
      margin-left: 0px;
    }
    h3 {
      font-size: 40px;
    }
  }
`

export const boxCta = css`
  margin-top: 24px;
  border-radius: 16px;
  border: 2px solid #776AAE;
  background: #776AAE;
  font-family:"owners-narrow";
  font-variation-settings: "wght" 700;
  padding: 50px 32px;
  position: sticky;
  top: 120px;
  h4 {
    color: #EAECED;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 48px */
    text-transform: uppercase;
    margin-bottom: 198px;
  }
  p {
    color: #EAECED !important;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    h4 {
      margin-bottom: 100px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h4 {
      margin-bottom: 100px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h4 {
      margin-bottom: 100px;
    }
  }
`


export const DDesktop = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: flex;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: flex;
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`