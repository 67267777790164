/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"

import { 
  SolucoesSectio,
  boxCta
}  from '../assets/styles/Solucoes.styles'

const Solucoes = () => {
  return (
    <Fragment>
      <section css={SolucoesSectio} id="solucoes">
        <Container>
          <Row>
            <Col lg={5} md={5} sm={12}>
              <div css={boxCta}>
                <h4>Criamos soluções integradas para escolas</h4>
                <p>Conheça nossos pilares de atuação</p>
              </div>
            </Col>
            <Col lg={1} md={1} sm={12} />
            <Col lg={6} md={6} sm={12}>
              <ul id="cards">
                <li className="card" id="card1" />
                <li className="card" id="card1">
                  <div className="card-body">
                    <h3>PESSOAS</h3>
                    <p>Priorizamos talentos, não ferramentas, pois sabemos que operações só vão adiante quando são guiadas pelas pessoas certas. Jogamos junto com os que querem aprimorar suas habilidades com foco na qualidade de suas entregas e desempenho.</p>
                    <p>Ao investir nas equipes, construímos a base para obter ganhos em receita e participação de mercado.</p>
                    <p>Conheça o Fractal Academy, nosso programa de aceleração e desenvolvimento de talentos.</p>
                  </div>
                </li>
                <li className="card" id="card2">
                  <div className="card-body">
                    <h3>PROCESSOS</h3>
                    <p>Somos um grupo com expertises complementares que objetiva desenvolver e oferecer experiências customizadas, ágeis e eficientes.</p>
                    <p>Com isso, criamos processos que levam em conta objetivos táticos e estratégicos que possam funcionar em curto, médio e longo prazo. Nossa proposta é reavaliar constantemente o que está sendo feito e reoxigenar as ideias para trilhar essas avenidas de crescimento e proporcionar resultados ainda melhores.</p>
                  </div>
                </li>
                <li className="card" id="card3">
                  <div className="card-body">
                    <h3>PLATAFORMAS</h3>
                    <p>Nossas soluções são desenvolvidas para agregar valor e diferenciação aos negócios e, ao mesmo tempo, identificar novas oportunidades de expansão.</p>
                    <p>Para tanto, nossas plataformas atendem às necessidades dos nossos parceiros com insights e ações concretas de negócio. Assim, assumimos o compromisso de multiplicar faturamentos e triplicar resultados, com ferramentas para chegar mais perto dos consumidores e para expandir o ecossistema das escolas.</p>
                  </div>
                </li>
                {/* <li className="card" id="card2">
                  <div className="card-body">
                    <h3>Card 2</h3>
                  </div>
                </li>
                <li className="card" id="card3">
                  <div className="card-body">
                    <h3>Card 3</h3>
                  </div>
                </li> */}
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default Solucoes;
