/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
// import CTAImg from "@/images/img/cta.png"

import { 
  CTASection,
  ImgPosition,
}  from '../assets/styles/CTA.styles'

const CTAContent = () => {
  return (
    <Fragment>
      <section css={CTASection} id="cta">
        <div css={ImgPosition}>
          {/* <img src={CTAImg} /> */}
        </div>
        <Container>
          <Row>
            <Col lg={7} md={7} sm={12} />
            <Col lg={5} md={5} sm={12}>
              <h3>Triplique a sua capacidade atual e <span>junte-se ao número de escolas que mais cresce no país</span></h3>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default CTAContent;
