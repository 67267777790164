/** @jsx jsx */
import { css } from '@emotion/react'

export const headerSection = css`
  /* padding-top: 118px;
  padding-bottom: 74px;
  margin-top: 86px; */
  background: #776BAB;
  font-family:"owners-narrow";
  h1 {
    font-variation-settings: "wght" 700;
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
    color: #EAECED;
    span {
      color: #CFC5E5;
    }
  }
  p {
    color: #EAECED;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    h1 {
      font-size: 60px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h1 {
      font-size: 60px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h1 {
      font-size: 60px;
    }
  }
`
export const videoSection = css`
  margin-top: -480px;
  @media (min-width: 320px) and (max-width: 767px) {
    margin-top: auto;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    margin-top: auto;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    margin-top: auto;
  }
`
export const DDesktop = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: flex;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: flex;
  }
`
export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none !important;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none !important;
  }
`