/** @jsx jsx */
import React from "react"
import { jsx } from '@emotion/react'

import { 
    video,
  }  from '../assets/styles/Video.styles'

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div css={video}>
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)
export default Video