import React from "react";
import Layout from "@/components/layout";
import MenuHeader from "@/components/header-one";
import HeaderContent from "@/components/HeaderContent";
import FractalAcademy from "@/components/FractalAcademyContent";
import Blog from "@/components/BlogContent";
import CTA from "@/components/CTA";
import Solucoes from "@/components/SolucoesContent";
import RRR from "@/components/RRRContent";
import Contatos from "@/components/Contatos";
import Footer from "@/components/footer";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";

const Index = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Home">
          <MenuHeader />
          <HeaderContent />
          <RRR />
          <Solucoes />
          <CTA />
          <Contatos />
          <FractalAcademy />
          {/* <Blog /> */}
          <Footer /> 
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default Index;
