/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import { Helmet } from "react-helmet";
import { withPrefix} from "gatsby"

import { 
  RRRSection,
  RRRSectionMob,
  DDesktop,
  DMobile,
}  from '../assets/styles/RRR.styles'

const Contatos = () => {
  return (
    <Fragment>
      <Helmet>
        <script src={withPrefix('script.js')} type="text/javascript" />
      </Helmet>
      <section css={[RRRSection, DDesktop]}>
        <div className="vertical-section"/>
        <div className="sticky-container">
          <main>
            <section style={{ background: `#EAECED` }} id="rethink">
              <Container>
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <h1>RETHINK</h1>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={12} />
                  <Col lg={1} md={1} sm={12} />
                  <Col lg={5} md={5} sm={12}>
                    <h4>Somos conscientes dos potenciais ainda inexplorados de crescimento e valorização do setor educacional.</h4>
                    <p>Por isso, atuamos como consultores especializados e investidores ativos para impulsionar escolas parceiras em sua trajetória de ascensão. Com nossa orientação, muitas delas triplicaram de tamanho e multiplicaram seus lucros em menos de 5 anos.</p>
                  </Col>
                </Row>
              </Container>
            </section>
            <section style={{ background: `#776AAE` }} id="reinvent">
              <Container>
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <h1 style={{ color: `#EAECED` }}>REINVENT</h1>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={12} />
                  <Col lg={1} md={1} sm={12} />
                  <Col lg={5} md={5} sm={12}>
                    <h4 style={{ color: `#CFC5E5` }}>Com nossas ferramentas e serviços compartilhados, as escolas parceiras presenciam o aperfeiçoamento de seus processos.</h4>
                    <p style={{ color: `#EAECED` }}>Enquanto oferecemos insights que pavimentam o caminho para um crescimento sustentável e expressivo, os gestores concentram sua atenção na expansão dos negócios e na conexão com a comunidade escolar.</p>
                  </Col>
                </Row>
              </Container>
            </section>
            <section style={{ background: `#EAECED` }} id="rebiz">
              <Container>
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <h1>REBIZ</h1>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={12} />
                  <Col lg={1} md={1} sm={12} />
                  <Col lg={5} md={5} sm={12}>
                    <h4>Transformamos nossa expertise em Negócios, Comunicação e Tecnologia, em plataformas proprietárias para escolas que buscam evolução contínua.</h4>
                    <p>Combinamos nosso conhecimento com as necessidades de cada escola para criar soluções exclusivas que reforçam a marca no mercado, ampliam sua escalabilidade e potencializam seu valor patrimonial.</p>
                  </Col>
                </Row>
              </Container>
            </section>
          </main>
        </div>
        <div className="vertical-section"/>
      </section>
      <div css={[RRRSectionMob, DMobile]} style={{ flexDirection: "column" }}>
          <section style={{ background: `#EAECED`, padding: '120px 0px' }} id="rethink">
              <Container>
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <h1>RETHINKK</h1>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={12} />
                  <Col lg={1} md={1} sm={12} />
                  <Col lg={5} md={5} sm={12}>
                    <h4>Somos conscientes dos potenciais ainda inexplorados de crescimento e valorização do setor educacional.</h4>
                    <p>Por isso, atuamos como consultores especializados e investidores ativos para impulsionar escolas parceiras em sua trajetória de ascensão. Com nossa orientação, muitas delas triplicaram de tamanho e multiplicaram seus lucros em menos de 5 anos.</p>
                  </Col>
                </Row>
              </Container>
            </section>
            <section style={{ background: `#776AAE`, padding: '120px 0px' }} id="reinvent">
              <Container>
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <h1 style={{ color: `#EAECED` }}>REINVENT</h1>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={12} />
                  <Col lg={1} md={1} sm={12} />
                  <Col lg={5} md={5} sm={12}>
                    <h4 style={{ color: `#CFC5E5` }}>Com nossas ferramentas e serviços compartilhados, as escolas parceiras presenciam o aperfeiçoamento de seus processos.</h4>
                    <p style={{ color: `#EAECED` }}>Enquanto oferecemos insights que pavimentam o caminho para um crescimento sustentável e expressivo, os gestores concentram sua atenção na expansão dos negócios e na conexão com a comunidade escolar.</p>
                  </Col>
                </Row>
              </Container>
            </section>
            <section style={{ background: `#EAECED`, padding: '120px 0px' }} id="rebiz">
              <Container>
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <h1>REBIZ</h1>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={12} />
                  <Col lg={1} md={1} sm={12} />
                  <Col lg={5} md={5} sm={12}>
                    <h4>Transformamos nossa expertise em Negócios, Comunicação e Tecnologia, em plataformas proprietárias para escolas que buscam evolução contínua.</h4>
                    <p>Combinamos nosso conhecimento com as necessidades de cada escola para criar soluções exclusivas que reforçam a marca no mercado, ampliam sua escalabilidade e potencializam seu valor patrimonial.</p>
                  </Col>
                </Row>
              </Container>
            </section>
        </div>
    </Fragment>
    
  );
};

export default Contatos;
