import { css } from '@emotion/react'

export const contatosEVisitas = css`
  background: #443B6D;
  padding: 40px 0px;
  font-family:"owners-narrow";
  font-variation-settings: "wght" 700;
  scroll-margin-top: 117px;
  h3 {
    color: #CFC5E5;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 48px */
    text-transform: uppercase;
    /* height: 100%; */
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #EAECED;
    margin-bottom: 0px;
  }
  .content-section {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  form {
    p {
      font-family:"owners-narrow";
      font-variation-settings: "wght" 700;
    }
    input {
      width: 100%;
      height: 52px;
      background: transparent;
      border-bottom: 2px solid #EFEFEF;
      border-top: none;
      border-left: none;
      border-right: none;
      padding-left: 0px;
      padding-right: 24px;
      outline: none;
      color: #EAECED;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 30.8px */
      text-transform: uppercase;
      &::placeholder {
        color: #EFEFEF;
      }
    }
    select {
      border-bottom: 2px solid #EFEFEF;
      border-top: none;
      border-left: none;
      border-right: none;
      width: 100%;
      height: 52px;
      background: transparent;
      outline: none;
      padding-left: 0px;
      padding-right: 24px;
      color: #EAECED;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 30.8px */
      text-transform: uppercase;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    h3 {
      margin-bottom: 56px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h3 {
      margin-bottom: 56px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h3 {
      margin-bottom: 56px;
    }
  }
`

export const btnEnviar = css`
  font-family:"owners-narrow";
  font-variation-settings: "wght" 700;
  border-radius: 12px;
  border: 1px solid #EAECED;
  background: #443B6D;
  padding: 14px 32px;
  display: flex;
  color: #EAECED;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 18px */
  text-transform: uppercase;
  transition: transform 2000ms ease,opacity 2000ms ease,color 500ms ease,background 500ms ease,-webkit-transform 2000ms ease;
  margin-top: 40px;
  :hover {
    background: #EAECED;
    border: 1px solid #EAECED;
    color: #776AAE;
  }
  :active {
    background: #776AAE;
    border: 1px solid #EAECED;
    color: #EAECED;
  }
`

export const DDesktop = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: flex;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: flex;
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`