import { css } from '@emotion/react'

export const RRRSection = css`
  background: transparent;
  font-family:"owners-narrow";
  font-variation-settings: "wght" 700;
  .vertical-section{
    height: 0vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  main {
    overflow-x: hidden;
    display: flex;
    position: sticky;
    top:0;
    scroll-margin-top: 140px;
  }

  h1 {
    color: #776AAE;
    font-size: 168px;
    font-style: normal;
    font-weight: 700;
    line-height: 96%;
  }

  h4 {
    color: #776AAE;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 30.8px */
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  h4:nth-child(even) {
    color: #EAECED;
  }

  p {
    color: #484848;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0px;
  }

  section {
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4ch;
  }

  section:nth-child(even) {
    background-color: red;
    color: red;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    h1 {
      font-size: 100px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h1 {
      font-size: 100px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h1 {
      font-size: 100px;
    }
  }
`

export const RRRSectionMob = css`
  background: transparent;
  font-family:"owners-narrow";
  font-variation-settings: "wght" 700;
  h1 {
    color: #776AAE;
    font-size: 168px;
    font-style: normal;
    font-weight: 700;
    line-height: 96%;
  }

  h4 {
    color: #776AAE;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 30.8px */
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  h4:nth-child(even) {
    color: #EAECED;
  }

  p {
    color: #484848;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    h1 {
      font-size: 85px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h1 {
      font-size: 85px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h1 {
      font-size: 85px;
    }
  }
`

export const DDesktop = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: initial;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: initial;
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`