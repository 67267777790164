/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import { Link } from "gatsby"

import { 
  academySection,
}  from '../assets/styles/FractalAcademy.styles'

const FractalAcademyContent = () => {
  return (
    <Fragment>
      <section css={academySection} id="academy">
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12} >
              <h3>Desenvolvemos ferramentas digitais e <span>investimos no potencial das pessoas</span></h3>
            </Col>
            <Col lg={1} md={1} sm={12} />
            <Col lg={5} md={5} sm={12}>
              <h4>Fractal Academy</h4>
              <p>Conectamos nosso ecossistema e expertises profissionais para favorecer o aprendizado, a troca e o crescimento.</p>
              <div className="justifyCenter">
                <Link to='/'>SAIBA MAIS</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default FractalAcademyContent;
